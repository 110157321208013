import * as Sentry from "@sentry/nextjs";
import { find, propEq, pipe, propOr, compose } from "ramda";
import { getConditionallyOnServer } from "@/utilities/getConditionally";
import { mapKeys } from "@/utilities/ramdaExt";
import { resolveClientPath } from "@/utilities/resolveClientPath";

export type Features = typeof defaultFeatures;
export type WithFeatures<T = Record<string, unknown>> = T & { features: Features };

// NOTE - ML: We don't want to hit the gitlab api every time the hook useFeature is triggered.
const cachedFeatures: typeof defaultFeatures | null = null;

export const defaultFeatures = {
  enable_login: false,
  enable_supply_filters: false,
  enable_similar_cars: false,
  enable_whitepapers_tagfilter: false,
  enable_supply_mutator_endpoint: false,
  enable_lease_calculator: process.env.ENABLE_LEASE_CALCULATOR === "true",
  enable_lease_calculator_attachments: false,
};

interface GitlabFeature {
  name: string;
  enabled: boolean;
}

const enabledOrFalse: (f: GitlabFeature | undefined) => boolean = propOr(false, "enabled");

const findEnabledFeatureOrFalse = (features: GitlabFeature[]) => (k: keyof Features) =>
  pipe(find<GitlabFeature>(propEq("name", k)), enabledOrFalse)(features);

const mapToFeaturesWithDefaults = compose(mapKeys, findEnabledFeatureOrFalse);

const retrieveServerside = async () => {
  return defaultFeatures;
  // if (cachedFeatures) return cachedFeatures;
  // try {
  //   const gitlabFeatures: GitlabFeature[] = await retrieveFeaturesFromGitlab();
  //   cachedFeatures = mapToFeaturesWithDefaults(gitlabFeatures)(defaultFeatures) as Features;
  //   return cachedFeatures;
  // } catch (e) {
  //   Sentry.captureException(e);
  //   return defaultFeatures;
  // }
};

const retrieveClientside: () => Promise<Features> = async () => {
  return defaultFeatures;

  // try {
  //   const response = await fetch(resolveClientPath("/api/app/features"), {
  //     headers: { Accept: "application/json" },
  //   });

  //   if (response.status !== 200) {
  //     Sentry.captureMessage("Failed to retrieve feature flags from endpoint", {
  //       level: "error",
  //       extra: { statusCode: response.status, responseBody: response.body },
  //     });
  //     return defaultFeatures;
  //   }
  //   return response.json();
  // } catch (err) {
  //   return defaultFeatures;
  // }
};

export const getFeatures = async () => {
  return getConditionallyOnServer(retrieveServerside, retrieveClientside);
};
