import { useEffect } from "react";
import { DataLayerElementType, dataLayerPush } from "@/components/shared/GTM/GTM";

interface useDataLayerEvent {
  blocked?: boolean;
  events: Array<DataLayerElementType>;
}

export const useDataLayerEvent = ({ blocked = false, events }: useDataLayerEvent) => {
  useEffect(() => {
    if (blocked) {
      return;
    }
    events.map((dataLayerEvent) => dataLayerPush(dataLayerEvent));
  }, [blocked]);
};
