import ContactFormInput from "@/components/shared//DialogForms/ContactFormInput";
import FormTextarea from "@/components/shared/Forms/FormTextarea";
import DialogFormWrapper from "@/components/shared/DialogForms/DialogFormWrapper";
import { SpecificAppointmentForm } from "@/services/salesforce/models/SpecificAppointmentForm";
import { emailIsValid } from "@/utilities/emailIsValid";
import { SpecificAppointmentFormProps } from "@/services/prepr/queries/getSpecificAppointmentForm";
import { useDataLayerEvent } from "@/hooks/useDatalayerEvent";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { LEAD_SOURCE } from "@/services/salesforce/models/LeadSource";
import { useFormErrorDataLayerPush } from "../GTM/useFormErrorDataLayerPush";
import { useFormState } from "./useFormState";

interface DialogProps {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (model: SpecificAppointmentForm) => void;
  onSubmitted?: () => void;
}

const buildInitialModel: (campaignTag: string) => SpecificAppointmentForm = (
  campaignTag: string
) => ({
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  email: "",
  subject: "",
  message: "",
  formSource: FORM_SOURCE_TYPE.SpecificAppointment,
  leadSource: LEAD_SOURCE.webform,
  campaignTag,
  slug: "",
});

const initialValidation = {
  firstName: null,
  lastName: null,
  companyName: null,
  phone: null,
  email: null,
  subject: null,
  message: null,
  formSource: null,
  leadSource: null,
};

const getValidationMessage =
  (model: SpecificAppointmentForm) =>
  (field: keyof SpecificAppointmentForm): string | null => {
    const value = model[field];
    if (field === "firstName" && !value) return "Voornaam is vereist";
    if (field === "lastName" && !value) return "Achternaam is vereist";
    if (field === "phone") {
      if (!value) return "Telefoonnummer is vereist";
      if (value.length < 10 || !/^(\+|\d)\d+$/.test(value)) {
        return "Telefoonnummer is ongeldig";
      }
    }
    if (field === "email") {
      if (!value) return "E-mail is vereist";
      if (!emailIsValid(value)) return "E-mail is ongeldig";
    }
    if (field === "subject" && !value) return "Onderwerp is vereist";
    return null;
  };

const DialogSpecificAppointment: React.FC<SpecificAppointmentFormProps & DialogProps> = ({
  title,
  introtext,
  campaigntag,
  isOpen,
  onClose,
  onSubmitted,
}) => {
  const initialModel = buildInitialModel(campaigntag || "unknown");
  const {
    isError,
    isSuccess,
    handler,
    model,
    validation,
    handleClose,
    validateAndSubmit,
    isLoading,
    setModel,
    hasValidationError,
  } = useFormState({
    initialModel,
    initialValidation,
    onSubmitted,
    onClose,
    getValidationMessage,
    entity: "lead",
  });
  const inputProps = { model, eventHandler: handler, validation, disabled: isLoading };

  useFormErrorDataLayerPush(`Specific Appointment: ${campaigntag}`, validation);

  useDataLayerEvent({
    blocked: !isSuccess,
    events: [
      {
        event: "formSuccess",
        formName: `Specific Appointment: ${campaigntag}`,
      },
    ],
  });

  const addSlugAndSubmit = () => {
    if (typeof window !== "undefined") {
      model.slug = window.location.pathname;
      setModel(model);
    }
    validateAndSubmit();
  };
  return (
    <DialogFormWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={addSlugAndSubmit}
      title={title || ""}
      description={introtext}
      submitText="Vraag gesprek aan"
      error={isError}
      isLoading={isLoading}
      hasValidationError={hasValidationError}
    >
      <>
        <div className="tablet:grid-cols-2 grid grid-cols-1 gap-x-6">
          <ContactFormInput label="Voornaam*" field="firstName" {...inputProps} />
          <ContactFormInput label="Achternaam*" field="lastName" {...inputProps} />
          <ContactFormInput label="Bedrijfsnaam" field="companyName" {...inputProps} />
          <ContactFormInput label="Telefoonnummer*" field="phone" {...inputProps} />
          <ContactFormInput label="E-mailadres*" field="email" {...inputProps} />
          <ContactFormInput label="Betreft*" field="subject" {...inputProps} />
        </div>
        <FormTextarea
          label="Bericht"
          value={model.message}
          onChange={handler.onFieldChange("message")}
          disabled={isLoading}
        />
      </>
    </DialogFormWrapper>
  );
};

export default DialogSpecificAppointment;
