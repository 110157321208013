import { domToReact } from "html-react-parser";
import type { DOMNode, HTMLReactParserOptions } from "html-react-parser";

export const parseU = (
  children: DOMNode[],
  options?: HTMLReactParserOptions | undefined,
  textClass?: string
) => (
  <u className={`${textClass ? textClass : "text-secondary-burgundy"} no-underline`}>
    {domToReact(children, options)}
  </u>
);
