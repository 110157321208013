import { createHash } from "crypto";
//@ts-ignore
import { createPreprClient } from "@preprio/nodejs-sdk";
import { DocumentNode, print } from "graphql";
import { Logger, SentryLogger } from "@/utilities/logger";
import { runTaskAndLogAsSpan } from "@/utilities/runTaskAndLogAsSpan";
import { getPublicStandaloneProps } from "@/getPublicStandaloneProps";
import { getFromNodeCache, setNodeCache } from "../nodeMemoryCache";

let preprClient: PreprClient;

interface CreateRunQuery {
  logger: Pick<Logger, "addBreadcrumb" | "logError" | "createLogBreadcrumb">;
}

export const preprTimoutErrorMessage = "Prepr fetch timeout";

const getPreprCacheTtl = () => {
  return process.env.PREPR_MEMORYCACHE_MINUTES
    ? parseInt(process.env.PREPR_MEMORYCACHE_MINUTES) * 60
    : 0;
};

export const runQuery = async <T>(
  query: DocumentNode,
  variables?: Record<string, any>
): Promise<T> => {
  const cacheKey = createHash("sha1").update(JSON.stringify({ query, variables })).digest("base64");
  const cacheTtl = getPreprCacheTtl();
  if (cacheTtl > 0) {
    const resultFromCache = getFromNodeCache<T>(cacheKey);
    if (resultFromCache) return resultFromCache;
  }
  const resultFromPrepr = await tryRunQuery<T>(query, variables);
  if (cacheTtl > 0) setNodeCache(cacheKey, resultFromPrepr, cacheTtl);
  return resultFromPrepr;
};

const createTryRunQuery =
  ({ logger }: CreateRunQuery) =>
  async <T>(query: DocumentNode, variables?: Record<string, any>): Promise<T> => {
    const logBreadcrumb = logger.createLogBreadcrumb({
      category: "Prepr request",
    });
    if (!preprClient) {
      const token = getPublicStandaloneProps().preprToken;
      if (!token) {
        throw new Error("Prepr token is missing in env");
      }
      preprClient = createPreprClient({
        token,
        baseUrl: "https://graphql.prepr.io/graphql",
        userId: null, // optional, used for AB testing
        timeout: 20000,
      });
    }
    logBreadcrumb(`Now posting query:`, {
      query: JSON.stringify((print(query) || "").substring(0, 100)),
      variables: JSON.stringify(variables),
    });
    const response = await runPreprQuery({ variables, query })(preprClient).catch((e) => {
      // PreprClient throws stringified errors so we cannot use e.name.
      // It throws a (stringified) AbortError when the timeout is reached.
      if (e?.message?.indexOf("AbortError") === 0) {
        logBreadcrumb("Prepr timeout for query", { data: print(query).substring(0, 30) });
        throw new Error(preprTimoutErrorMessage, { cause: e });
      } else {
        throw e;
      }
    });
    if (response.errors) {
      throw new Error(JSON.stringify(response.errors));
    }
    if (!response.data) {
      throw new Error(JSON.stringify(response));
    }
    logBreadcrumb(`Response resolved:`, { response: JSON.stringify(response.data) });
    return response.data;
  };

const tryRunQuery = createTryRunQuery({ logger: SentryLogger });
interface PreprQuery {
  query: DocumentNode;
  variables?: Record<string, any>;
}

interface PreprClient {
  graphqlVariables: (v?: Record<string, any>) => PreprClient;
  graphqlQuery: (q: string) => PreprClient;
  fetch: () => Promise<any>;
}

const runPreprQuery =
  ({ query, variables }: PreprQuery) =>
  async (preprClient: PreprClient) => {
    const task = () => preprClient.graphqlVariables(variables).graphqlQuery(print(query)).fetch();
    const spanOptions = {
      op: "preprClient.fetch",
      description: "A GraphQL query to Prepr",
      tags: { task_type: "Prepr request" },
      data: { query, variables },
    };
    return runTaskAndLogAsSpan(SentryLogger, spanOptions, task);
  };
