import { useEffect } from "react";
import { hasWindow } from "@/utilities/predicates/hasWindow";
import { CookieSettings } from "@/components/blocks/CookieNotification/CookieNotification";

export const useSetConsentFromStorage = () => {
  useEffect(() => {
    if (!localStorage) {
      return;
    }
    const cookieSettings = localStorage.getItem("cookieConsentSettings");
    if (typeof cookieSettings === "string") {
      const parsedCookieSettings = JSON.parse(cookieSettings);
      if (parsedCookieSettings && typeof parsedCookieSettings === "object") {
        updateConsent(parsedCookieSettings);
      }
    }
  }, []);
};

export const GTMNoScriptTag = (gtmId?: string) => {
  return gtmId ? (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  ) : (
    <></>
  );
};

export const updateConsent = (settings: CookieSettings) => {
  const consentLevels = {
    functional_consent: settings ? "granted" : "denied",
    statistics_consent: settings.statistics_consent ? "granted" : "denied",
    marketing_consent: settings.marketing_consent ? "granted" : "denied",
  };
  gtag("consent", "update", consentLevels);
  dataLayerPush({
    event: "consent_level_change",
    consent_levels: consentLevels,
  });
};

export type DataLayerElementType = Record<string, unknown>;
type GtagType = string | Record<string, unknown>;

export const gtag = (...args: Array<GtagType>) => {
  if (!hasWindow()) return;
  // @ts-ignore
  if (typeof window.dataLayer !== "object" || !window.gtag) return;
  // @ts-ignore
  window.gtag(...args);
};

export const dataLayerPush = (...args: Array<DataLayerElementType>) => {
  if (!hasWindow()) return;
  // @ts-ignore
  if (typeof window.dataLayer !== "object" || !window.gtag) return;
  // @ts-ignore
  window.dataLayer.push(...args);
};
