import ContactFormInput from "@/components/shared//DialogForms/ContactFormInput";
import DialogFormWrapper from "@/components/shared/DialogForms/DialogFormWrapper";
import { emailIsValid } from "@/utilities/emailIsValid";
import { SalesforceEntity } from "@/services/salesforce/models/SalesForceEntity";
import { useDataLayerEvent } from "@/hooks/useDatalayerEvent";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { InventoryFinancingContact } from "@/services/salesforce/models/InventoryFinancingContact";
import { LEAD_SOURCE } from "@/services/salesforce/models/LeadSource";
import { useFormErrorDataLayerPush } from "../GTM/useFormErrorDataLayerPush";
import { useFormState } from "./useFormState";

interface Props {
  title: string;
  description: string;
  submitbuttontext: string;
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (model: InventoryFinancingContact) => void;
  onSubmitted?: () => void;
  className?: string;
  entity: SalesforceEntity;
  campaigntag: string;
}

const buildInitialModel = (campaignTag: string): InventoryFinancingContact => ({
  firstName: "",
  lastName: "",
  companyName: "",
  companyCity: "",
  kvk: "",
  phone: "",
  email: "",
  loanAmount: "",
  formSource: FORM_SOURCE_TYPE.InventoryFinancingContact,
  leadSource: LEAD_SOURCE.webform,
  campaignTag,
});

const initialValidation = {
  firstName: null,
  lastName: null,
  companyName: null,
  companyCity: null,
  kvk: null,
  phone: null,
  email: null,
  loanAmount: null,
  formSource: null,
  campaignTag: null,
  leadSource: null,
};

const getValidationMessage =
  (model: InventoryFinancingContact) =>
  (field: keyof InventoryFinancingContact): string | null => {
    const value = model[field];
    switch (true) {
      case field === "firstName" && !value:
        return "Voornaam is vereist";
      case field === "lastName" && !value:
        return "Achternaam is vereist";
      case field === "companyName" && !value:
        return "Bedrijfsnaam is vereist";
      case field === "companyCity" && !value:
        return "Vestigingsplaats bedrijf is vereist";
      case field === "loanAmount" && !value:
        return "Leningsbedrag is vereist";
      case field === "loanAmount" && value && Number(value) < 200000:
        return `Voor het product "Voorraad Financiering" hanteren wij een ondergrens van €200.000,-. Mocht je niet zeker weten of dit het product is waar je naar op zoek bent, neem dan contact op met onze klantenservice: 088-5543900.`;
      case field === "kvk" && value && !/^[0-9]{8}$/.test(value):
        return "KvK-nummer moet 8 cijfers bevatten";
      case field === "phone" && !value:
        return "Telefoonnummer is vereist";
      case field === "phone" && (value.length < 10 || !/^(\+|\d)\d+$/.test(value)):
        return "Telefoonnummer is ongeldig";
      case field === "email" && !value:
        return "E-mail is vereist";
      case field === "email" && !emailIsValid(value):
        return "E-mail is ongeldig";
      default:
        return null;
    }
  };

const DialogInventoryFinancing: React.FC<Props> = ({
  title,
  description,
  submitbuttontext,
  isOpen,
  onClose,
  onSubmitted,
  entity,
  campaigntag,
}) => {
  const initialModel = buildInitialModel(campaigntag);
  const {
    isError,
    handler,
    model,
    validation,
    handleClose,
    validateAndSubmit,
    isLoading,
    isSuccess,
    hasValidationError,
  } = useFormState({
    initialModel,
    initialValidation,
    onSubmitted,
    onClose,
    getValidationMessage,
    entity: entity || "lead",
  });
  useDataLayerEvent({
    blocked: !isSuccess,
    events: [
      {
        event: "formSuccess",
        formName: `InventoryFinancing Form: ${campaigntag}`,
      },
    ],
  });
  useFormErrorDataLayerPush(`InventoryFinancing Form: ${campaigntag}`, validation);
  const inputProps = { model, eventHandler: handler, validation, disabled: isLoading };
  return (
    <DialogFormWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={validateAndSubmit}
      title={title}
      description={description}
      submitText={submitbuttontext}
      error={isError}
      isLoading={isLoading}
      hasValidationError={hasValidationError}
    >
      <>
        <div className="tablet:grid-cols-2 grid grid-cols-1 gap-x-6">
          <ContactFormInput label="Voornaam*" field="firstName" {...inputProps} />
          <ContactFormInput label="Achternaam*" field="lastName" {...inputProps} />
          <ContactFormInput label="Bedrijfsnaam*" field="companyName" {...inputProps} />
          <ContactFormInput label="Vestigingsplaats bedrijf*" field="companyCity" {...inputProps} />
          <ContactFormInput label="Telefoonnummer*" field="phone" {...inputProps} />
          <ContactFormInput label="E-mailadres*" field="email" {...inputProps} />
          <ContactFormInput
            label="Leningsbedrag* (minimaal €200.000,-)"
            field="loanAmount"
            {...inputProps}
          />
          <ContactFormInput label="KvK nummer" field="kvk" {...inputProps} />
        </div>
      </>
    </DialogFormWrapper>
  );
};

export default DialogInventoryFinancing;
