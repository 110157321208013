import { ReactNode } from "react";
import Dialog from "@/components/shared/DialogForms/Dialog";
import Button from "@/components/shared/Button";
import { parseText } from "@/utilities/htmlParser";
import Spinner from "../Spinner";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit: () => void;
  title: string;
  description: string | JSX.Element | ReactNode;
  submitText: string;
  error?: boolean | undefined;
  children: JSX.Element;
  className?: string | undefined;
  isLoading?: boolean;
  hasValidationError?: boolean | undefined;
}

const DialogFormWrapper = <K extends keyof T, T extends { [X in K]: string }>(props: Props) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    title,
    description,
    submitText,
    children,
    error,
    className,
    isLoading,
    hasValidationError,
  } = props;
  const parsedDescription = typeof description === "string" ? parseText(description) : description;
  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={className}>
      <Spinner visible={!!isLoading} text="Versturen..." />
      <div className="text-primary-black tablet:p-12 p-6 pb-8">
        <h2 className="text-h3-sm tablet:text-h2-sm mb-4 font-sans font-bold tracking-wider">
          {title}
        </h2>
        <div className="text-p-sm tablet:text-p mb-8 font-serif tracking-wider">
          {parsedDescription}
        </div>
        <div className="bg-grey-light tablet:py-10 tablet:px-12 rounded-lg p-4 pb-10">
          {children}
          <div className="tablet:flex-row tablet:items-center tablet:gap-8 flex flex-col items-start gap-6">
            <Button
              variant="tertiary"
              onClick={onSubmit}
              className="tablet:w-auto w-full"
              size="small"
              aria-label="submit"
              disabled={isLoading}
            >
              {submitText}
            </Button>
            {hasValidationError && (
              <p
                className="border-secondary-grapefruit text-p-sm text-secondary-aubergine tablet:max-w-none tablet:text-p flex w-full max-w-[420px] items-center justify-center rounded-lg border bg-white
               p-4 text-center font-bold"
              >
                Let op: niet alle velden zijn juist ingevuld
              </p>
            )}
          </div>
          {error && (
            <div className="text-p text-secondary-aubergine mt-6 w-full text-center font-serif font-bold tracking-wider">
              Er ging iets mis! Probeer later nog eens. Excuses voor het ongemak.
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DialogFormWrapper;
