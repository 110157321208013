import { hasWindow } from "./utilities/predicates/hasWindow";

export type PublicStandaloneProps = {
  appVersion: string | undefined;
  gtmId: string | undefined;
  preprToken: string | undefined;
  mockHasura: boolean;
  sentry: { environment: string | undefined; sampleRate: number };
  trengoKey: string | undefined;
};

const parseBoolean = (value?: string | null | undefined): boolean => {
  if (value === undefined || value === null) return false;
  const lcValue = value.toLowerCase();
  return lcValue === "1" || lcValue === "true" || lcValue === "yes";
};

const parseSentrySampleRate = (value?: string | null | undefined): number => {
  const parsedSampleRate = Number(value);
  return parsedSampleRate > 1 ? 1 : parsedSampleRate < 0 ? 0 : parsedSampleRate || 0.05;
};

const publicStandaloneProps: PublicStandaloneProps = {
  appVersion: process.env.NEXT_PUBLIC_APP_BUILD_ID, // appVersion will be hardcoded at buildtime
  gtmId: process.env.GTM_ID,
  preprToken: process.env.PREPR_TOKEN,
  mockHasura: parseBoolean(process.env.MOCK_HASURA),
  sentry: {
    environment: process.env.SENTRY_ENVIRONMENT,
    sampleRate: parseSentrySampleRate(process.env.SENTRY_SAMPLE_RATE),
  },
  trengoKey: process.env.TRENGO_KEY,
};

export const getPublicStandaloneProps = (): PublicStandaloneProps => {
  // @ts-ignore `window.publicStandaloneProps` is defined in _document.tsx
  return hasWindow() ? window.publicStandaloneProps : publicStandaloneProps;
};
