import { useEffect, useRef, useState } from "react";

const useStateAndDelayNull = <T>(
  initialValue: T,
  delay: number
): [T, (value: T) => void, () => void] => {
  const timer = useRef<NodeJS.Timeout>();
  const [state, setState] = useState<T>(initialValue);

  const dispatch = (value: T) => {
    if (value === null) {
      timer.current = setTimeout(() => {
        setState(value);
      }, delay);
    } else {
      setState(value);
      clearTimeout(timer.current);
    }
  };

  const resetDelay = () => {
    clearTimeout(timer.current);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return [state, dispatch, resetDelay];
};

export default useStateAndDelayNull;
