import { useEffect } from "react";
import usePrevious from "@/hooks/usePrevious";
import { dataLayerPush } from "./GTM";

export const useFormErrorDataLayerPush = <T,>(formName: string, validation: T) => {
  const previousValidation = usePrevious(validation);
  useEffect(() => {
    let k: keyof typeof validation;
    for (k in validation) {
      if (!validation[k]) continue;
      if (previousValidation && previousValidation[k] === validation[k]) continue;
      dataLayerPush({
        event: "formError",
        formError: validation[k],
        formName: formName,
      });
    }
  }, [validation, previousValidation]);
};
