import { ContactForm } from "@/services/salesforce/models/ContactForm";
import { emailIsValid } from "@/utilities/emailIsValid";

export const getValidationMessage =
  (model: ContactForm) =>
  (field: keyof ContactForm): string | null => {
    const value = model[field];
    if (field === "firstName" && !value) return "Voornaam is vereist";
    if (field === "lastName" && !value) return "Achternaam is vereist";
    if (field === "phone") {
      if (!value) return "Telefoonnummer is vereist";
      if (value.length < 10 || !/^(\+|\d)\d+$/.test(value)) {
        return "Telefoonnummer is ongeldig";
      }
    }
    if (field === "email") {
      if (!value) return "E-mail is vereist";
      if (!emailIsValid(value)) return "E-mail is ongeldig";
    }
    if (field === "subject" && !value) return "Onderwerp is vereist";
    return null;
  };
