import React from "react";

interface Props {
  name: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  testId?: string;
}

const Checkbox: React.FC<Props> = ({
  name,
  label,
  value,
  checked,
  onChange,
  className = "font-serif text-p tracking-wider",
  disabled = false,
  testId,
}) => (
  <div className={className}>
    <input
      data-testid={testId}
      type="checkbox"
      value={value}
      id={name}
      name={name}
      checked={checked}
      onChange={onChange}
      className="float-left mt-2 cursor-pointer"
      disabled={disabled}
    />
    <label
      htmlFor={name}
      className="ml-3 block cursor-pointer select-none pl-4"
      data-testid={`${testId}-label`}
    >
      {label}
    </label>
  </div>
);

export default Checkbox;
