import ContactFormInput from "@/components/shared//DialogForms/ContactFormInput";
import FormTextarea from "@/components/shared/Forms/FormTextarea";
import DialogFormWrapper from "@/components/shared/DialogForms/DialogFormWrapper";
import { MakeAppointmentForm } from "@/services/salesforce/models/MakeAppointmentForm";
import { emailIsValid } from "@/utilities/emailIsValid";
import { SalesforceEntity } from "@/services/salesforce/models/SalesForceEntity";
import { useDataLayerEvent } from "@/hooks/useDatalayerEvent";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { LEAD_SOURCE } from "@/services/salesforce/models/LeadSource";
import { useFormErrorDataLayerPush } from "../GTM/useFormErrorDataLayerPush";
import { useFormState } from "./useFormState";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (model: MakeAppointmentForm) => void;
  onSubmitted?: () => void;
  className?: string;
  entity: SalesforceEntity;
}

const initialModel: MakeAppointmentForm = {
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  email: "",
  subject: "",
  message: "",
  formSource: FORM_SOURCE_TYPE.MakeAppointment,
  leadSource: LEAD_SOURCE.webform,
};

const initialValidation = {
  firstName: null,
  lastName: null,
  companyName: null,
  phone: null,
  email: null,
  subject: null,
  message: null,
  formSource: null,
  leadSource: null,
};

const getValidationMessage =
  (model: MakeAppointmentForm) =>
  (field: keyof MakeAppointmentForm): string | null => {
    const value = model[field];
    if (field === "firstName" && !value) return "Voornaam is vereist";
    if (field === "lastName" && !value) return "Achternaam is vereist";
    if (field === "phone") {
      if (!value) return "Telefoonnummer is vereist";
      if (value.length < 10 || !/^(\+|\d)\d+$/.test(value)) {
        return "Telefoonnummer is ongeldig";
      }
    }
    if (field === "email") {
      if (!value) return "E-mail is vereist";
      if (!emailIsValid(value)) return "E-mail is ongeldig";
    }
    if (field === "subject" && !value) return "Onderwerp is vereist";
    return null;
  };

const DialogMakeAppointment: React.FC<Props> = ({ isOpen, onClose, onSubmitted, entity }) => {
  const {
    isError,
    handler,
    model,
    validation,
    handleClose,
    validateAndSubmit,
    isLoading,
    isSuccess,
    hasValidationError,
  } = useFormState({
    initialModel,
    initialValidation,
    onSubmitted,
    onClose,
    getValidationMessage,
    entity: entity || "lead",
  });

  useDataLayerEvent({
    blocked: !isSuccess,
    events: [
      {
        event: "formSuccess",
        formName: "Adviesgesprek",
      },
    ],
  });
  useFormErrorDataLayerPush("MakeAppointment", validation);
  const inputProps = { model, eventHandler: handler, validation, disabled: isLoading };
  return (
    <DialogFormWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={validateAndSubmit}
      title="Vraag adviesgesprek aan"
      description="Hieronder vind je het contactformulier van Hiltermann. Vul hier je gegevens in en je
          gewenste moment dat wij contact met je kunnen opnemen. Wij komen dan bij je terug."
      submitText="Vraag gesprek aan"
      error={isError}
      isLoading={isLoading}
      hasValidationError={hasValidationError}
    >
      <>
        <div className="tablet:grid-cols-2 grid grid-cols-1 gap-x-6">
          <ContactFormInput
            label="Voornaam*"
            field="firstName"
            {...inputProps}
            disabled={isLoading}
          />
          <ContactFormInput
            label="Achternaam*"
            field="lastName"
            {...inputProps}
            disabled={isLoading}
          />
          <ContactFormInput
            label="Bedrijfsnaam"
            field="companyName"
            {...inputProps}
            disabled={isLoading}
          />
          <ContactFormInput
            label="Telefoonnummer*"
            field="phone"
            {...inputProps}
            disabled={isLoading}
          />
          <ContactFormInput
            label="E-mailadres*"
            field="email"
            {...inputProps}
            disabled={isLoading}
          />
          <ContactFormInput label="Betreft*" field="subject" {...inputProps} disabled={isLoading} />
        </div>
        <FormTextarea
          label="Bericht"
          value={model.message}
          onChange={handler.onFieldChange("message")}
          disabled={isLoading}
        />
      </>
    </DialogFormWrapper>
  );
};

export default DialogMakeAppointment;
