export const accountLinks: { href: string; name: string; target: "_blank" | "_self" }[] = [
  {
    href: "https://hiltermannlease.i-wise.net/Default.aspx?ReturnUrl=%2f",
    name: "Mijn wagenpark",
    target: "_blank",
  },
  {
    href: "https://www.mobilityonline.eu/nl/hl/auth/login",
    name: "Online calculeren berijders",
    target: "_blank",
  },
  {
    href: "https://ic.autodisk.online/hl/auth/login",
    name: "Intermediairs & Dealers",
    target: "_blank",
  },
  { href: "/service/aanmeldformulier-ott", name: "Aflevering auto", target: "_self" },
];
