import { assoc, curry, keys, mapObjIndexed, reduce, toPairs } from "ramda";

export const isTruthy = (x: any) => !!x;

export const isFalsy = (x: any) => !x;

export const propIsFalsy = curry((prop, obj) => !obj[prop]);

export const truthyPropOr = curry((fallback, prop, obj) =>
  propIsFalsy(prop, obj) ? fallback : obj[prop]
);

export const mapKeys = curry(<T extends object>(f: (k: keyof T) => unknown, obj: T) =>
  mapObjIndexed((_, k) => f(k as keyof T), obj)
);

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

export const renameKeysOnly = curry((keysMap, obj) =>
  reduce((acc, kvp) => assoc(kvp[1], obj[kvp[0]], acc), {}, toPairs(keysMap))
);
