/* eslint-disable no-console */
import * as Sentry from "@sentry/nextjs";
import { Breadcrumb } from "@sentry/nextjs";
import { SpanContext } from "@sentry/types/types/span";

type Sentry = typeof Sentry;
const sentry: Sentry = Sentry;

interface Transaction {
  finish: () => void;
}

export type SentrySpanOptions = Pick<
  SpanContext,
  Exclude<keyof SpanContext, "sampled" | "traceId" | "parentSpanId">
>;

export interface Logger {
  logError: (exception: any) => void;
  startTransaction: (name: string) => Transaction;
  startSpan: (options: SentrySpanOptions) => {
    finish: () => void;
    setStatus: (status: string) => any;
  } | null;
  addBreadcrumb: (b: Breadcrumb) => void;
  createLogBreadcrumb: ({ category }: { category: string }) => (
    message: string,
    data?: {
      [key: string]: any;
    }
  ) => void;
}

const doNothing = () => {
  // do nothing
};

export const createMockLogger = (): Logger => ({
  logError: doNothing,
  startTransaction: () => ({
    finish: doNothing,
  }),
  startSpan: () => ({
    finish: doNothing,
    setStatus: doNothing,
  }),
  addBreadcrumb: doNothing,
  createLogBreadcrumb: () => () => undefined,
});

export const SentryLogger: Logger = {
  logError: function (exception: any) {
    console.error(exception);
    sentry.captureException(exception, { level: "error" });
  },
  startTransaction: function (name: string) {
    const transaction = sentry.startTransaction({ op: "transaction", name });
    sentry.configureScope((scope: any) => scope.setSpan(transaction));
    return {
      finish: () => {
        transaction.finish();
      },
    };
  },
  startSpan: function (options: SentrySpanOptions) {
    const currentTransaction = sentry.getCurrentHub().getScope()?.getTransaction();
    if (currentTransaction) {
      return currentTransaction.startChild(options);
    }
    return null;
  },
  addBreadcrumb: function (b: Breadcrumb) {
    sentry.addBreadcrumb(b);
  },
  createLogBreadcrumb:
    ({ category }: { category: string }) =>
    (
      message: string,
      data?: {
        [key: string]: any;
      }
    ) =>
      sentry.addBreadcrumb({
        category: category,
        message,
        data,
        level: "info",
      }),
};
