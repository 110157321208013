import gql from "graphql-tag";

export const ImagePresetsFragment = gql(`#graphql
    fragment ImagePresetsFragment on Asset {
        mobile: url(preset: "mobile")
        desktop: url(preset: "desktop")
        default: url(preset: "default")
        url: url
        description
    }
`);
