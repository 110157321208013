import * as React from "react";
import { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.75 4.25h5.714A3.377 3.377 0 0 0 9.75 6.875a3.373 3.373 0 0 0 3.284-2.625H23.25c.413 0 .75-.337.75-.75a.752.752 0 0 0-.75-.75H13.036A3.375 3.375 0 0 0 9.75.125 3.375 3.375 0 0 0 6.464 2.75H.75A.752.752 0 0 0 0 3.5c0 .413.338.75.75.75Zm9-2.625a1.876 1.876 0 1 1-.001 3.749 1.876 1.876 0 0 1 .001-3.749Zm13.5 8.625h-4.215a3.33 3.33 0 0 0-3.285-2.625 3.373 3.373 0 0 0-3.284 2.625H.75A.752.752 0 0 0 0 11c0 .412.338.75.75.75h11.714a3.377 3.377 0 0 0 3.286 2.625 3.373 3.373 0 0 0 3.285-2.625h4.215c.413 0 .75-.338.75-.75a.752.752 0 0 0-.75-.75Zm-7.5 2.625A1.877 1.877 0 0 1 13.875 11a1.876 1.876 0 1 1 1.875 1.875Zm7.5 4.875H11.536a3.333 3.333 0 0 0-3.286-2.625 3.373 3.373 0 0 0-3.285 2.625H.75a.752.752 0 0 0-.75.75c0 .413.338.75.75.75h4.215a3.374 3.374 0 0 0 3.285 2.625 3.373 3.373 0 0 0 3.284-2.625H23.25c.413 0 .75-.337.75-.75a.752.752 0 0 0-.75-.75Zm-15 2.625A1.877 1.877 0 0 1 6.375 18.5a1.876 1.876 0 1 1 1.875 1.875Z"
      fill="#0B1423"
    />
  </svg>
);

const Sliders = memo(SvgComponent);
export default Sliders;
