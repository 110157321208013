import { useState } from "react";
import { useMutation } from "react-query";
import { all, any, lensProp, reduce, scan, set, toPairs, values } from "ramda";
import { FileWithPath } from "react-dropzone";
import { fetchThrow } from "@/utilities/fetchThrow";
import { validateModel, ValidationModel } from "@/components/shared/DialogForms/formValidation";
import { SalesforceEntity } from "@/services/salesforce/models/SalesForceEntity";
import { parseDate, parseDateNumbers } from "@/utilities/parseDate";

export type FieldMapper<T> = (field: string | number | symbol, model: T) => T;

export interface UseFormStateParams<T> {
  initialModel: T;
  initialValidation: ValidationModel<T>;
  onSubmitted?: () => void;
  onClose?: () => void;
  getValidationMessage: (v: T) => (k: keyof T) => string | null;
  validateFiles?: (files: FileWithPath[]) => string[] | null;
  validateMultipleFiles?: (
    files: { [key: string]: FileWithPath[] },
    fileNames: string[]
  ) => { [key: string]: string } | null;
  entity: SalesforceEntity;
  interdependentFields?: (keyof T)[];
  // use a fieldMapper to modify any field in the model when a field is changed
  fieldMapper?: FieldMapper<T>;
}

export interface useFormStateMethods<T> {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  handler: Handler<T>;
  model: T;
  validation: ValidationModel<T>;
  hasValidationError: boolean;
  handleClose: () => void;
  validateAndSubmit: (e?: any, scanFiles?: boolean) => void;
  selectedFiles: FileWithPath[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileWithPath[]>>;
  selectedMultipleFiles: { [key: string]: FileWithPath[] };
  setSelectedMultipleFiles: React.Dispatch<React.SetStateAction<{ [key: string]: FileWithPath[] }>>;
  selectedFilesError: string[] | null;
  selectedMultipleFilesError: { [key: string]: string } | null;
  setModel: React.Dispatch<React.SetStateAction<T>>;
  setValidation: React.Dispatch<React.SetStateAction<ValidationModel<T>>>;
}

interface Handler<T> {
  // Needs exception 'any' for selectinput
  onFieldChange(field: keyof T): (value: T[keyof T] | any) => void;
  onBlur(field: keyof T): () => void;
}

// type Model<T> = T extends Record<[keyof T], string>;

export const useFormState = <T extends { [K in keyof T]: string | File | null }>({
  initialModel,
  initialValidation,
  onSubmitted,
  onClose,
  getValidationMessage,
  validateFiles,
  entity,
  interdependentFields,
  fieldMapper,
}: UseFormStateParams<T>): useFormStateMethods<T> => {
  const [model, setModel] = useState<T>(initialModel);
  const [validation, setValidation] = useState<ValidationModel<T>>(initialValidation);
  const [selectedFilesError, setSelectedFilesError] = useState<string[] | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileWithPath[]>([]);
  const [selectedMultipleFilesError, setSelectedMultipleFilesError] = useState<{
    [key: string]: string;
  } | null>({});
  const [selectedMultipleFiles, setSelectedMultipleFiles] = useState<{
    [key: string]: FileWithPath[];
  }>({});
  const { mutate, isError, isSuccess, isLoading } = usePostForm<T>({
    onSuccess: () => {
      if (onSubmitted) onSubmitted();
      setModel(initialModel);
      setSelectedFiles([]);
      setSelectedMultipleFiles({});
      setValidation(initialValidation);
    },
    entity,
  });
  const { mutate: mutateScanFiles } = useSendToScanFiles<T>({
    onSuccess: () => {
      if (onSubmitted) onSubmitted();
      setModel(initialModel);
      setSelectedFiles([]);
      setSelectedMultipleFiles({});
      setValidation(initialValidation);
    },
  });
  const handler = {
    onFieldChange(field: keyof T) {
      return (value: T[keyof T]) => {
        if (typeof value !== "string" && typeof value !== "boolean" && value !== null) {
          const tempValue = value as any;
          if (!(tempValue instanceof Date)) {
            value = tempValue.target.value;
          }
        }
        const newModel = fieldMapper
          ? fieldMapper(field, set(lensProp(field), value, model))
          : set(lensProp(field), value, model);
        setModel(newModel);
        if (validation[field]) {
          const fieldsToUpdate = interdependentFields?.includes(field)
            ? interdependentFields
            : [field];
          const newValidation = reduce(
            (a, nextField) => ({ ...a, [nextField]: getValidationMessage(newModel)(nextField) }),
            validation,
            fieldsToUpdate
          );
          setValidation(newValidation);
        }
      };
    },
    onBlur(field: keyof T) {
      return () => {
        const validationMessage = getValidationMessage(model)(field);
        updateValidation(field, validationMessage);
      };
    },
  };
  const validate = validateModel<keyof T, T>(getValidationMessage);
  const updateValidation = (field: keyof T, message: string | null) => {
    const newValidation = set(lensProp(field), message, validation);
    setValidation(newValidation);
  };
  const handleClose = () => {
    if (onClose) onClose();
    setModel(initialModel);
    setValidation(initialValidation);
  };

  const hasValidationError =
    selectedFilesError !== null || any((x) => x !== null, values(validation));

  const validateMultipleFiles = (
    selectedFiles: { [key: string]: FileWithPath[] },
    fileNames: string[]
  ) => {
    const fileErrors: { [key: string]: string } = {};
    fileNames.map((fileName: string) => {
      if (!selectedFiles[fileName] || selectedFiles[fileName].length < 1) {
        return (fileErrors[fileName] = "Upload de benodigde bestanden");
      }
    });
    return fileErrors;
  };

  const validateAndSubmit = (_e: any, scanFiles = false) => {
    const validationResult = validate(model);
    setValidation(validationResult);
    const filesError = (validateFiles && validateFiles(selectedFiles)) || null;
    const noErrors = all((error) => error === null, values(validationResult)) && !filesError;
    setSelectedFilesError(filesError);

    const fileNames: string[] = Object.keys(model).filter((x) => x.startsWith("file_"));
    const multipleFilesError =
      (validateMultipleFiles && validateMultipleFiles(selectedMultipleFiles, fileNames)) || null;
    const noErrorsMultiple =
      all((error) => error === null, values(validationResult)) &&
      multipleFilesError &&
      Object.keys(multipleFilesError).length === 0;
    setSelectedMultipleFilesError(multipleFilesError);

    if (noErrors && noErrorsMultiple) {
      const parsedMultipleFiles = Object.entries(selectedMultipleFiles).map((item) => {
        return item[1][0];
      });
      if (scanFiles) {
        mutateScanFiles({ model, files: selectedFiles.concat(parsedMultipleFiles) });
      } else {
        mutate({ model, files: selectedFiles.concat(parsedMultipleFiles) });
      }
    }
  };

  return {
    isSuccess,
    isError,
    isLoading,
    handler,
    model,
    validation,
    hasValidationError,
    handleClose,
    validateAndSubmit,
    selectedFiles,
    setSelectedFiles,
    selectedMultipleFiles,
    setSelectedMultipleFiles,
    selectedFilesError,
    selectedMultipleFilesError,
    setModel,
    setValidation,
  };
};

interface UsePostFormParams {
  onSuccess: () => void;
  entity: SalesforceEntity;
}
interface MutationParam<T> {
  model: T;
  files?: File[];
}
export const usePostForm = <T>({ onSuccess, entity }: UsePostFormParams) => {
  return useMutation(
    (form: MutationParam<T>) => {
      const formData = new FormData();
      form.files?.forEach((file: File) => formData.append("files", file));
      const model = form.model;
      toPairs(model as unknown as Record<string, string>).forEach(([key, value]) =>
        formData.append(key, value)
      );
      return fetchThrow(`/api/salesforce/${entity}`, { method: "POST", body: formData });
    },
    { onSuccess }
  );
};

interface UseSendToScanFilesParams {
  onSuccess: () => void;
}
export const useSendToScanFiles = <T>({ onSuccess }: UseSendToScanFilesParams) => {
  return useMutation(
    async (form: MutationParam<T>) => {
      const formData = new FormData();
      const model = form.model;
      try {
        form.files?.forEach((file: File) => formData.append("files", file));
        toPairs(model as unknown as Record<string, string>).forEach(([key, value]) =>
          formData.append(key, value)
        );

        return fetchThrow(`/api/scanFiles`, { method: "POST", body: formData });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        return null;
      }
    },
    { onSuccess }
  );
};
