import React from "react";

interface ButtonContentProps {
  icon?: React.ReactElement;
  iconPosition: "start" | "end" | "center";
  children: JSX.Element | string;
}

const ButtonContent: React.FC<ButtonContentProps> = ({ icon, iconPosition, children }) => {
  return (
    <>
      {icon && iconPosition === "start" && <span className="mr-2 flex shrink-0">{icon}</span>}
      <span className="line-clamp-2">{children}</span>
      {icon && iconPosition === "center" && <span className="flex shrink-0">{icon}</span>}
      {icon && iconPosition === "end" && <span className="ml-2 flex shrink-0">{icon}</span>}
    </>
  );
};

export default ButtonContent;
