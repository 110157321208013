import { NextRouter } from "next/dist/client/router";
import { Maybe } from "@/utilities/maybe";

export const getCanonical = (router: NextRouter, canonicalUrl: Maybe<string | undefined>) => {
  let url;

  if (canonicalUrl) {
    url = `https://www.hiltermannlease.nl${
      canonicalUrl.slice(-1) === "/"
        ? canonicalUrl.substring(0, canonicalUrl.length - 1)
        : canonicalUrl || ""
    }`;
  } else {
    const routerPath = router.asPath;
    url = `https://www.hiltermannlease.nl${
      routerPath.slice(-1) === "/"
        ? routerPath.substring(0, routerPath.length - 1)
        : routerPath.split("?")[0] || ""
    }`;
  }

  let params = "";

  if (router.query?.["filter"]) {
    params = `?filter=${router.query["filter"]}`;
  }

  return `${url}${params}`;
};
