import { PrimaryNavigationNode } from "@/services/prepr/queries/getLayoutContent";
import NavbarMobile from "./Navbar/NavbarMobile";
import NavbarDesktop from "./Navbar/NavbarDesktop";

export type NavbarType = "default" | "fiets";
export interface NavbarProps {
  navigation: PrimaryNavigationNode[];
  type?: NavbarType;
}

/* If this needs to be extended more, we probably should create 
a reusable menu component (using mediaqueries for the mobile/desktop variants)
*/
const Navbar: React.FC<NavbarProps> = ({ navigation, type }) => (
  <>
    <NavbarMobile navigation={navigation} type={type} />
    <NavbarDesktop navigation={navigation} type={type} />
  </>
);

export default Navbar;
