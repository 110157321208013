export const isProduction = () => {
  const appEnv = process.env.APP_ENV;
  const isProduction = !(
    appEnv === "dev" ||
    appEnv === "tst" ||
    appEnv === "acc" ||
    appEnv === "local"
  ); // assume env is production if it is not one of these
  return isProduction;
};
