import TextLink from "@/components/shared/TextLink";
import { Feature } from "@/components/shared/Feature";
import { accountLinks } from "../accountLinks";

const AccountMenu: React.FC<{
  show: boolean;
}> = ({ show }) => (
  <div
    className={`absolute inset-x-0 top-full transition-all ${
      show ? "visible translate-y-0" : "invisible -translate-y-full"
    }`}
  >
    <div className="max-w-screen-desktop-xl mx-auto flex justify-end">
      <div className="bg-primary-black min-w-[417px] p-6 text-white">
        <Feature require="enable_login">
          <h3 className="text-menu text-secondary-grapefruit mb-1 font-bold">
            Waar wil je inloggen?
          </h3>
        </Feature>
        <ul>
          {accountLinks.map(({ href, name, target }) => (
            <li key={name} className="pt-5">
              <TextLink href={href} variant="dark" className="font-bold" target={target}>
                {name}
              </TextLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default AccountMenu;
