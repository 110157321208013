import { HTMLInputTypeAttribute } from "react";
import FormInput from "@/components/shared/Forms/FormInput";

interface ContactFormInputProps<T extends Record<string, any>> {
  label: string;
  type?: HTMLInputTypeAttribute;
  field: keyof T;
  model: T;
  validation: { [A in keyof T]: string | null };
  eventHandler: {
    onFieldChange: (field: keyof T) => (value: string) => void;
    onBlur: (field: keyof T) => (value: string) => void;
  };
  disabled?: boolean | undefined;
}

function ContactFormInput<T extends Record<string, any>>({
  label,
  type,
  field,
  model,
  eventHandler,
  validation,
  disabled,
}: ContactFormInputProps<T>) {
  const value = model[field];
  return (
    <FormInput
      label={label}
      value={value}
      type={type}
      onBlur={eventHandler.onBlur(field)}
      onChange={eventHandler.onFieldChange(field)}
      error={validation[field]}
      className="tablet:mb-6 mb-4"
      disabled={disabled}
    />
  );
}

export default ContactFormInput;
