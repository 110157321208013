import React, { PropsWithChildren, ReactElement } from "react";
import { FeatureContext } from "@/hooks/useFeature";
import { Features } from "@/services/features/featureService";

interface Props {
  require: keyof Features;
  children: ReactElement;
  fallback?: ReactElement | null;
}

export const Feature: React.FC<Props> = ({
  require,
  children,
  fallback,
}: PropsWithChildren<Props>) => (
  <FeatureContext.Consumer>
    {(features) => (features[require] ? children : fallback || null)}
  </FeatureContext.Consumer>
);
