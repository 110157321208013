import parse from "html-react-parser";
import type { DOMNode } from "html-react-parser";
import sanitize from "sanitize-html";
import { textParser } from "./htmlParser/textParser";
import { quoteParser } from "./htmlParser/quoteParser";
import { underlineParser } from "./htmlParser/underlineParser";

type ReactParserReplace =
  | ((domNode: DOMNode) => false | void | object | JSX.Element | null | undefined)
  | undefined;

const parseWith = (replace: ReactParserReplace) => (text: string) =>
  parse(sanitize(text), { replace });

export const parseText = parseWith(textParser("normal"));
export const parseTextDark = parseWith(textParser("dark"));

export const parseQuote = parseWith(quoteParser("normal"));
export const parseQuoteDark = parseWith(quoteParser("dark"));

export const parseUnderline = parseWith(underlineParser);
