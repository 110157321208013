import gql from "graphql-tag";

export const NavTargetFragment = gql`
  fragment NavTargetFragment on PrimaryNavigationNode_Target {
    ... on BlogPage {
      title
      _slug
    }
    ... on CustomerStoryPage {
      title
      _slug
    }
    ... on DynamicPage {
      title
      _slug
    }
    ... on StaticPage {
      title
      _slug
    }
  }
`;
