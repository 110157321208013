import { lensProp, set } from "ramda";
import {
  PrimaryNavigationNode,
  SecondaryNavigationNode,
  TertiaryNavigationNode,
} from "@/services/prepr/queries/getLayoutContent";

const getQueryStringFromObject = (params?: Record<string, string>) => {
  return params ? new URLSearchParams(params).toString() : undefined;
};

export const getUrlFromNavigationNode = (
  node: PrimaryNavigationNode | SecondaryNavigationNode | TertiaryNavigationNode
) => {
  const { target, query_params } = node;
  const anyTarget = target[0] as any;
  const url = anyTarget?._slug ?? anyTarget?.url;
  if (!url) return "/404";

  const params = getQueryStringFromObject(
    query_params?.reduce(
      (result: Record<string, string>, { key, value }) =>
        key && value ? set(lensProp(key), value, result) : result,
      {}
    )
  );
  if (params) {
    return `${url}?${params}`;
  }
  return url;
};
