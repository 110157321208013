import { spanStatusfromHttpCode } from "@sentry/tracing";
import { Logger, SentrySpanOptions } from "./logger";

export async function runTaskAndLogAsSpan<T>(
  logger: Pick<Logger, "startSpan">,
  options: SentrySpanOptions,
  task: () => Promise<T>
) {
  const span = logger.startSpan(options);
  try {
    const thing = (await task()) as T;
    span?.setStatus(spanStatusfromHttpCode(200));
    return thing;
  } catch (err) {
    span?.setStatus(spanStatusfromHttpCode(500));
    throw err;
  } finally {
    span?.finish();
  }
}
