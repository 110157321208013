import ContactFormInput from "@/components/shared/DialogForms/ContactFormInput";
import FormTextarea from "@/components/shared/Forms/FormTextarea";
import { CallMeBackForm } from "@/services/salesforce/models/CallMeBackForm";
import DialogFormWrapper from "@/components/shared/DialogForms/DialogFormWrapper";
import { useFormState } from "@/components/shared/DialogForms/useFormState";
import { SalesforceEntity } from "@/services/salesforce/models/SalesForceEntity";
import { useFormErrorDataLayerPush } from "@/components/shared/GTM/useFormErrorDataLayerPush";
import { useDataLayerEvent } from "@/hooks/useDatalayerEvent";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { getValidationMessage } from "./DialogCallMeBack/validation";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (model: CallMeBackForm) => void;
  onSubmitted: () => void;
  entity?: SalesforceEntity;
}

const initialModel: CallMeBackForm = {
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  email: "",
  subject: "",
  message: "",
  formSource: FORM_SOURCE_TYPE.CallMeBack,
};

const initialValidation = {
  firstName: null,
  lastName: null,
  companyName: null,
  phone: null,
  email: null,
  subject: null,
  message: null,
  formSource: null,
};

const DialogCallMeBack: React.FC<Props> = ({ isOpen, onClose, onSubmitted, entity = "case" }) => {
  const {
    isError,
    handler,
    model,
    validation,
    handleClose,
    validateAndSubmit,
    isLoading,
    isSuccess,
    hasValidationError,
  } = useFormState({
    initialModel,
    initialValidation,
    onSubmitted,
    onClose,
    getValidationMessage,
    entity: entity,
  });

  useDataLayerEvent({
    blocked: !isSuccess,
    events: [
      {
        event: "formSuccess",
        formName: "CallMeBack",
      },
    ],
  });

  useFormErrorDataLayerPush("CallMeBack", validation);
  const inputProps = { model, eventHandler: handler, validation, disabled: isLoading };
  return (
    <DialogFormWrapper
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={validateAndSubmit}
      title="Plan een terugbelafspraak met Hiltermann"
      description="Hieronder vind je het contactformulier van Hiltermann. Vul hier je gegevens in en je
          gewenste moment dat wij contact met je kunnen opnemen. Wij komen dan bij je terug."
      submitText="Neem contact met mij op"
      error={isError}
      hasValidationError={hasValidationError}
    >
      <>
        <div
          className="tablet:grid-cols-2 grid grid-cols-1 gap-x-6"
          data-testid="call-me-back-dialog-form"
        >
          <ContactFormInput label="Voornaam*" field="firstName" {...inputProps} />
          <ContactFormInput label="Achternaam*" field="lastName" {...inputProps} />
          <ContactFormInput label="Bedrijfsnaam" field="companyName" {...inputProps} />
          <ContactFormInput label="Telefoonnummer*" field="phone" {...inputProps} />
          <ContactFormInput label="E-mailadres*" field="email" {...inputProps} />
          <ContactFormInput label="Betreft*" field="subject" {...inputProps} />
        </div>
        <FormTextarea
          label="Bericht"
          value={model.message}
          onChange={handler.onFieldChange("message")}
          disabled={isLoading}
        />
      </>
    </DialogFormWrapper>
  );
};

export default DialogCallMeBack;
