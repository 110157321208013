import ContactFormInput from "@/components/shared//DialogForms/ContactFormInput";
import FormTextarea from "@/components/shared/Forms/FormTextarea";
import DialogFormWrapper from "@/components/shared/DialogForms/DialogFormWrapper";
import { RecruitmentForm } from "@/services/salesforce/models/RecruitmentForm";
import { emailIsValid } from "@/utilities/emailIsValid";
import { SalesforceEntity } from "@/services/salesforce/models/SalesForceEntity";
import { useDataLayerEvent } from "@/hooks/useDatalayerEvent";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { useFormErrorDataLayerPush } from "../GTM/useFormErrorDataLayerPush";
import { useFormState } from "./useFormState";

interface Props {
  title: string;
  description: string;
  submitbuttontext: string;
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (model: RecruitmentForm) => void;
  onSubmitted?: () => void;
  className?: string;
  entity: SalesforceEntity;
  campaigntag: string;
}

const buildInitialModel = (campaignTag: string): RecruitmentForm => ({
  firstName: "",
  lastName: "",
  linkedInLink: "",
  phone: "",
  email: "",
  message: "",
  formSource: FORM_SOURCE_TYPE.Recruitment,
  campaignTag,
});

const initialValidation = {
  firstName: null,
  lastName: null,
  linkedInLink: null,
  phone: null,
  email: null,
  message: null,
  formSource: null,
  campaignTag: null,
};

const getValidationMessage =
  (model: RecruitmentForm) =>
  (field: keyof RecruitmentForm): string | null => {
    const value = model[field];
    if (field === "firstName" && !value) return "Voornaam is vereist";
    if (field === "lastName" && !value) return "Achternaam is vereist";
    if (field === "phone") {
      if (!value) return "Telefoonnummer is vereist";
      if (value.length < 10 || !/^(\+|\d)\d+$/.test(value)) {
        return "Telefoonnummer is ongeldig";
      }
    }
    if (field === "email") {
      if (!value) return "E-mail is vereist";
      if (!emailIsValid(value)) return "E-mail is ongeldig";
    }
    return null;
  };

const DialogRecruitment: React.FC<Props> = ({
  title,
  description,
  submitbuttontext,
  isOpen,
  onClose,
  onSubmitted,
  entity,
  campaigntag,
}) => {
  const initialModel = buildInitialModel(campaigntag);
  const {
    isError,
    handler,
    model,
    validation,
    handleClose,
    validateAndSubmit,
    isLoading,
    isSuccess,
    hasValidationError,
  } = useFormState({
    initialModel,
    initialValidation,
    onSubmitted,
    onClose,
    getValidationMessage,
    entity: entity || "lead",
  });
  useDataLayerEvent({
    blocked: !isSuccess,
    events: [
      {
        event: "formSuccess",
        formName: `Recruitment Form: ${campaigntag}`,
      },
    ],
  });
  useFormErrorDataLayerPush(`Recruitment Form: ${campaigntag}`, validation);
  const inputProps = { model, eventHandler: handler, validation, disabled: isLoading };
  return (
    <DialogFormWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={validateAndSubmit}
      title={title}
      description={description}
      submitText={submitbuttontext}
      error={isError}
      isLoading={isLoading}
      hasValidationError={hasValidationError}
    >
      <>
        <div className="tablet:grid-cols-2 grid grid-cols-1 gap-x-6">
          <ContactFormInput label="Voornaam*" field="firstName" {...inputProps} />
          <ContactFormInput label="Achternaam*" field="lastName" {...inputProps} />
          <ContactFormInput
            label="Link naar LinkedIn profiel"
            field="linkedInLink"
            {...inputProps}
          />
          <ContactFormInput label="Telefoonnummer*" field="phone" {...inputProps} />
          <ContactFormInput label="E-mailadres*" field="email" {...inputProps} />
        </div>
        <FormTextarea
          label="Bericht"
          value={model.message}
          onChange={handler.onFieldChange("message")}
          disabled={isLoading}
        />
      </>
    </DialogFormWrapper>
  );
};

export default DialogRecruitment;
