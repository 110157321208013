import React, { createContext, useContext, useState } from "react";
import DialogCallMeBack from "@/components/pages/Contact/ContactServiceBlock/DialogCallMeBack";
import DialogContact from "@/components/pages/Contact/ContactServiceBlock/DialogContact";
import DialogMakeAppointment from "@/components/shared/DialogForms/DialogMakeAppointment";
import { SpecificAppointmentFormProps } from "@/services/prepr/queries/getSpecificAppointmentForm";
import { DialogForm, WhitepaperDialogProps } from "@/services/prepr/queries/getLayoutContent";
import { SalesforceEntity } from "@/services/salesforce/models/SalesForceEntity";
import DialogSpecificAppointment from "../shared/DialogForms/DialogSpecificAppointment";
import DialogMessage from "../shared/DialogForms/DialogMessage";
import DialogWhitepaper from "../shared/DialogForms/DialogWhitepaper";
import DialogRecruitment from "../shared/DialogForms/DialogRecruitment";
import DialogInventoryFinancing from "../shared/DialogForms/DialogInventoryFinancingContact";
import { DialogFormType } from "./DialogFormType";

export type ActiveDialog =
  | null
  | "lead-contact"
  | "case-contact"
  | "lead-callMeBack"
  | "case-callMeBack"
  | "lead-requestAppointment"
  | "case-requestAppointment"
  | "lead-recruitment"
  | "case-recruitment"
  | "requestSpecificAppointment"
  | "message"
  | "whitepaper"
  | "inventoryFinancingContact";

export interface DialogMessageState {
  headerText?: string;
  bodyText?: string;
  closeButtonText?: string;
  image?: string;
  imageDescription?: string | null;
  downloadUrl?: string;
  downloadTitle?: string;
}

const doNothing = () => {
  // do nothing
};

const DialogContext = createContext<
  [
    ActiveDialog,
    ExtraWhitepaperProps | null,
    DialogMessageState | null,
    SpecificAppointmentFormProps | null,
    React.Dispatch<React.SetStateAction<ActiveDialog>>,
    React.Dispatch<React.SetStateAction<string>>,
    React.Dispatch<React.SetStateAction<ExtraWhitepaperProps | null>>,
    React.Dispatch<React.SetStateAction<DialogMessageState | null>>,
    React.Dispatch<React.SetStateAction<SpecificAppointmentFormProps | null>>
  ]
>([null, null, null, null, doNothing, doNothing, doNothing, doNothing, doNothing]);

interface Props {
  children?: React.ReactNode;
  dialogs: DialogForm[];
  whitepaperDialog?: WhitepaperDialogProps | null;
}

interface ExtraWhitepaperProps {
  slug: string;
  title: string;
  body: string;
  whitepaperUrl: string;
}

export const DialogWrapper: React.FC<Props> = ({ children, dialogs, whitepaperDialog }) => {
  const [activeDialog, setActiveDialog] = useState<ActiveDialog>(null);
  const [activeDialogName, setActiveDialogName] = useState<string>("");
  const [extraWhitepaperProps, setExtraWhitepaperProps] = useState<ExtraWhitepaperProps | null>(
    null
  );
  const [dialogMessageState, setDialogMessageState] = useState<DialogMessageState | null>(null);
  const [specificAppointmentState, setSpecificAppointmentState] =
    useState<SpecificAppointmentFormProps | null>(null);

  const openThankYouForSpecificAppointment = () => {
    setActiveDialog("message");
    setDialogMessageState({
      headerText: "Bedankt voor je aanvraag!",
      bodyText: specificAppointmentState?.confirmationtext ?? "",
      image: specificAppointmentState?.image?.[0]?.default ?? undefined,
      imageDescription: specificAppointmentState?.image?.[0]?.description,
    });
  };
  const openWhitepaperThankYouDialog = () => {
    setActiveDialog("message");
    setDialogMessageState({
      headerText: whitepaperDialog?.downloadscreentitle || "",
      bodyText: whitepaperDialog?.downloadscreenbody || "",
      closeButtonText: "Venster sluiten",
      downloadUrl: extraWhitepaperProps?.whitepaperUrl,
      downloadTitle: extraWhitepaperProps?.title,
    });
  };

  const fallbackTitle = "Bedankt voor je aanvraag!";
  const fallbackText =
    "Je vindt een bevestiging in je inbox. Wij gaan met je aanvraag aan de slag en komen zo snel mogelijk bij je terug.";
  const openMessageDialog =
    ({ confirmationtitle, confirmationtext, confirmation_image }: DialogForm) =>
    () => {
      setDialogMessageState({
        headerText: confirmationtitle || fallbackTitle,
        bodyText: confirmationtext || fallbackText,
        closeButtonText: "Venster sluiten",
        image: confirmation_image?.[0]?.default || "/images/lars-bakker-square.png",
        imageDescription: confirmation_image?.[0]?.description,
      });
      setActiveDialog("message");
    };
  const closeWhateverDialogIsActive = () => setActiveDialog(null);

  return (
    <>
      <DialogContext.Provider
        value={[
          activeDialog,
          extraWhitepaperProps,
          dialogMessageState,
          specificAppointmentState,
          setActiveDialog,
          setActiveDialogName,
          setExtraWhitepaperProps,
          setDialogMessageState,
          setSpecificAppointmentState,
        ]}
      >
        {children}
      </DialogContext.Provider>
      {dialogs.map((dialogForm, index) => {
        const entity = dialogForm.salesforce_entity as SalesforceEntity;
        switch (dialogForm.form_type as DialogFormType) {
          case "Contact":
            return entity === "lead" ? (
              <DialogContact
                key={`contact-lead-${index}`}
                isOpen={
                  activeDialog === "lead-contact" &&
                  (activeDialogName === dialogForm.name || !activeDialogName)
                }
                onClose={closeWhateverDialogIsActive}
                setActiveDialog={setActiveDialog}
                onSubmitted={openMessageDialog(dialogForm)}
                entity={entity}
                title={dialogForm.title}
                description={dialogForm.description}
              />
            ) : (
              <DialogContact
                key={`contact-case-${index}`}
                isOpen={
                  activeDialog === "case-contact" &&
                  (activeDialogName === dialogForm.name || !activeDialogName)
                }
                onClose={closeWhateverDialogIsActive}
                setActiveDialog={setActiveDialog}
                onSubmitted={openMessageDialog(dialogForm)}
                entity={entity}
                title={dialogForm.title}
                description={dialogForm.description}
                messagePlaceholder="Wil je contact om iets te wijzigingen of op te vragen? Klik dan bovenin op de link."
              />
            );
          case "CallMeBack":
            return (
              <DialogCallMeBack
                key={`callmeback-${entity}-${index}`}
                isOpen={
                  activeDialog === `${entity}-callMeBack` &&
                  (activeDialogName === dialogForm.name || !activeDialogName)
                }
                onClose={closeWhateverDialogIsActive}
                onSubmitted={openMessageDialog(dialogForm)}
                entity={entity}
              />
            );
          case "RequestAppointment":
            return (
              <DialogMakeAppointment
                key={`makeappointment-${entity}-${index}`}
                isOpen={
                  activeDialog === `${entity}-requestAppointment` &&
                  (activeDialogName === dialogForm.name || !activeDialogName)
                }
                onClose={closeWhateverDialogIsActive}
                onSubmitted={openMessageDialog(dialogForm)}
                entity={entity}
              />
            );
          case "Recruitment":
            return (
              <DialogRecruitment
                key={`recruitment-${entity}-${index}`}
                isOpen={
                  activeDialog === `${entity}-recruitment` &&
                  (activeDialogName === dialogForm.name || !activeDialogName)
                }
                onClose={closeWhateverDialogIsActive}
                onSubmitted={openMessageDialog(dialogForm)}
                entity="lead"
                title={dialogForm.title || "Werken bij Hiltermann"}
                description={dialogForm.description || ""}
                submitbuttontext={dialogForm.submitbuttontext || "Vraag gesprek aan"}
                campaigntag={dialogForm.campaigntag || "Ongespecificeerd"}
              />
            );
          case "InventoryFinancingContact":
            return (
              <DialogInventoryFinancing
                key={`inventory-financing-${entity}-${index}`}
                title={dialogForm.title || "Vraag adviesgesprek aan"}
                description={
                  dialogForm.description ||
                  "Hieronder vind je het contactformulier van Hiltermann. Vul hier je gegevens in en je gewenste moment dat wij contact met je kunnen opnemen. Wij komen dan bij je terug."
                }
                submitbuttontext={dialogForm.submitbuttontext || "Vraag gesprek aan"}
                onSubmitted={openMessageDialog(dialogForm)}
                entity="lead"
                campaigntag={dialogForm.campaigntag || "Ongespecificeerd"}
                isOpen={activeDialog === "inventoryFinancingContact"}
                onClose={closeWhateverDialogIsActive}
              />
            );
          default:
            return null;
        }
      })}
      <DialogSpecificAppointment
        {...specificAppointmentState}
        isOpen={activeDialog === "requestSpecificAppointment"}
        onClose={closeWhateverDialogIsActive}
        onSubmitted={openThankYouForSpecificAppointment}
      />
      <DialogMessage
        isOpen={activeDialog === "message"}
        closeButtonText={dialogMessageState?.closeButtonText}
        headerText={dialogMessageState?.headerText}
        bodyText={dialogMessageState?.bodyText}
        image={dialogMessageState?.image}
        imageDescription={dialogMessageState?.imageDescription}
        onClose={closeWhateverDialogIsActive}
        downloadUrl={dialogMessageState?.downloadUrl}
        downloadTitle={dialogMessageState?.downloadTitle}
      />
      {whitepaperDialog && (
        <DialogWhitepaper
          isOpen={activeDialog === "whitepaper"}
          onClose={closeWhateverDialogIsActive}
          onSubmitted={openWhitepaperThankYouDialog}
          {...extraWhitepaperProps}
          {...whitepaperDialog}
        ></DialogWhitepaper>
      )}
    </>
  );
};

export function useDialogForms() {
  const [
    activeDialog,
    extraWhitepaperProps,
    dialogMessageState,
    specificAppointmentState,
    setActiveDialog,
    setActiveDialogName,
    setExtraWhitepaperProps,
    setDialogMessageState,
    setSpecificAppointmentState,
  ] = useContext(DialogContext);

  const openWhitepaperDialog =
    (slug: string, title: string, body: string, whitepaperUrl: string) => () => {
      setActiveDialog("whitepaper"), setExtraWhitepaperProps({ slug, title, body, whitepaperUrl });
    };
  const closeWhateverDialogIsActive = () => setActiveDialog(null);
  const openMessageDialog =
    (state?: DialogMessageState | null | undefined, callback?: () => void) => () => {
      setActiveDialog("message");
      setDialogMessageState(state || null);
      if (callback) callback();
    };
  const openSpecificAppointmentForm = (state?: SpecificAppointmentFormProps | null | undefined) => {
    setActiveDialog("requestSpecificAppointment");
    setSpecificAppointmentState(state || null);
  };
  const openWhitepaperThankYouDialog = openMessageDialog({
    headerText: "Bedankt voor je aanvraag!",
    bodyText: `De whitepaper '${
      extraWhitepaperProps?.title || ""
    }' is onderweg naar je inbox. Heb je niks ontvangen?
        Controleer dan je spam folder.`,
    closeButtonText: "Venster sluiten",
  });

  const openDialog = (dialog: ActiveDialog, dialogName: string) => () => {
    setActiveDialog(dialog);
    setActiveDialogName(dialogName ?? "");
  };

  return {
    activeDialog,
    dialogMessageState,
    openSpecificAppointmentForm,
    openWhitepaperDialog,
    closeWhateverDialogIsActive,
    openWhitepaperThankYouDialog,
    openMessageDialog,
    setDialogMessageState,
    specificAppointmentState,
    setSpecificAppointmentState,
    openDialog,
  };
}
