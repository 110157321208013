import NodeCache from "node-cache";

//@ts-ignore
if (!global.nodeCache) {
  //@ts-ignore
  global.nodeCache = new NodeCache({ stdTTL: 60 });
}
//@ts-ignore
export const nodeMemoryCache = global.nodeCache as NodeCache;

export const setNodeCache = (cacheKey: string, value: any, ttlSeconds: number) => {
  nodeMemoryCache.set(cacheKey, value, ttlSeconds);
};

export const getFromNodeCache = <T>(cacheKey: string): T | undefined => {
  return nodeMemoryCache.get<T>(cacheKey);
};
