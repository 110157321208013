// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { getPublicStandaloneProps } from "./getPublicStandaloneProps";

const props = getPublicStandaloneProps().sentry;
const SENTRY_ENVIRONMENT = props.environment;
const SENTRY_SAMPLE_RATE = props.sampleRate;

Sentry.init({
  dsn: "https://9f689140e6cf4dc488a0a07f16ec2a5e@o4504367213051904.ingest.sentry.io/4505436969172992",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: SENTRY_ENVIRONMENT,
  maxBreadcrumbs: 20,
});
