import { Logger, SentrySpanOptions } from "./logger";
import { runTaskAndLogAsSpan } from "./runTaskAndLogAsSpan";

/**
 * Throws the result as an error if the response status is not in 200 range
 */
export const fetchThrow = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
  return fetch(input, init).then(async (r) => {
    if (r.status < 200 || r.status > 299) {
      const msg = await r.text();
      throw new Error(`${r.status} ${msg}`);
    }
    return r;
  });
};

export const fetchThrowAndLogAsSpan =
  (logger: Pick<Logger, "startSpan">) =>
  (options: SentrySpanOptions) =>
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    const task = () => fetchThrow(input, init);
    return runTaskAndLogAsSpan(logger, options, task);
  };

export const postJson = (input: RequestInfo | URL, data: any): Promise<Response> =>
  fetchThrow(input, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
